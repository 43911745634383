'use client';
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import tailwindTheme from './../../tailwind.config';

const colors = tailwindTheme.theme.extend.colors;

const COLORS = {
  white: { main: colors.white, contrastText: colors.black },
  black: { main: colors.black, contrastText: colors.white },
  'site-background': { main: colors['site-background'] },
  'dialog-backdrop': { main: colors['dialog-backdrop'] },
  'brand.blue': { main: colors.brand.blue, contrastText: '#fff' },
  'brand.orange': { main: colors.brand.orange, contrastText: '#fff' },
  'blue-1': { main: colors.blue[1], contrastText: '#fff' },
  'blue-2': { main: colors.blue[2], contrastText: '#fff' },
  'blue-3': { main: colors.blue[3], contrastText: '#fff' },
  'blue-4': { main: colors.blue[4], contrastText: '#fff' },
  'cyan-1': { main: colors.cyan[1], contrastText: '#fff' },
  'cyan-2': { main: colors.cyan[2], contrastText: '#fff' },
  'cyan-3': { main: colors.cyan[3], contrastText: '#fff' },
  'cyan-4': { main: colors.cyan[4], contrastText: '#fff' },
  'emerald-1': { main: colors.emerald[1], contrastText: '#fff' },
  'emerald-2': { main: colors.emerald[2], contrastText: '#fff' },
  'emerald-3': { main: colors.emerald[3], contrastText: '#fff' },
  'emerald-4': { main: colors.emerald[4], contrastText: '#fff' },
  'green-1': { main: colors.green[1], contrastText: '#fff' },
  'green-2': { main: colors.green[2], contrastText: '#fff' },
  'green-3': { main: colors.green[3], contrastText: '#fff' },
  'green-4': { main: colors.green[4], contrastText: '#fff' },
  'lime-1': { main: colors.lime[1], contrastText: '#fff' },
  'lime-2': { main: colors.lime[2], contrastText: '#fff' },
  'lime-3': { main: colors.lime[3], contrastText: '#fff' },
  'lime-4': { main: colors.lime[4], contrastText: '#fff' },
  'navy-1': { main: colors.navy[1], contrastText: '#fff' },
  'navy-2': { main: colors.navy[2], contrastText: '#fff' },
  'navy-3': { main: colors.navy[3], contrastText: '#fff' },
  'navy-4': { main: colors.navy[4], contrastText: '#fff' },
  'orange-1': { main: colors.orange[1], contrastText: '#fff' },
  'orange-2': { main: colors.orange[2], contrastText: '#fff' },
  'orange-3': { main: colors.orange[3], contrastText: '#fff' },
  'orange-4': { main: colors.orange[4], contrastText: '#fff' },
  'pink-1': { main: colors.pink[1], contrastText: '#fff' },
  'pink-2': { main: colors.pink[2], contrastText: '#fff' },
  'pink-3': { main: colors.pink[3], contrastText: '#fff' },
  'pink-4': { main: colors.pink[4], contrastText: '#fff' },
  'purple-1': { main: colors.purple[1], contrastText: '#fff' },
  'purple-2': { main: colors.purple[2], contrastText: '#fff' },
  'purple-3': { main: colors.purple[3], contrastText: '#fff' },
  'purple-4': { main: colors.purple[4], contrastText: '#fff' },
  'red-1': { main: colors.red[1], contrastText: '#fff' },
  'red-2': { main: colors.red[2], contrastText: '#fff' },
  'red-3': { main: colors.red[3], contrastText: '#fff' },
  'red-4': { main: colors.red[4], contrastText: '#fff' },
  'yellow-1': { main: colors.yellow[1], contrastText: '#fff' },
  'yellow-2': { main: colors.yellow[2], contrastText: '#fff' },
  'yellow-3': { main: colors.yellow[3], contrastText: '#fff' },
  'yellow-4': { main: colors.yellow[4], contrastText: '#fff' },
  grey: { ...grey },
  'grey-1': { main: colors.grey[1], contrastText: '#fff' },
  'grey-2': { main: colors.grey[2], contrastText: '#fff' },
  'grey-3': { main: colors.grey[3], contrastText: '#fff' },
  'grey-4': { main: colors.grey[4], contrastText: '#fff' },
  'grey-5': { main: colors.grey[5], contrastText: '#fff' },
  'grey-6': { main: colors.grey[6], contrastText: '#fff' },
  'grey-7': { main: colors.grey[7], contrastText: '#fff' },
  'grey-8': { main: colors.grey[8], contrastText: '#fff' },
  'grey-9': { main: colors.grey[9], contrastText: '#fff' },
};
export const theme = createTheme({
  palette: COLORS,
  shape: {
    // @ts-ignore
    rounded: tailwindTheme.theme.extend.borderRadius,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          '& .MuiInputBase-input': {
            color: COLORS['grey-8'].main,
          },
          '& .Mui-disabled, & .MuiInputBase-input.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        // @ts-ignore
        color: 'purple-2',
        sx: {
          '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
            transition: 'border-color 0.2s',
          },
          '& .MuiInputBase-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: COLORS['purple-2'].main,
            },
          '& .MuiInputBase-input': {
            color: COLORS['grey-8'].main,
          },
          '& .MuiInputBase-root': {
            borderRadius: '6px',
          },
          '& .MuiFormLabel-root:not(.Mui-focused)': {
            color: COLORS['grey-7'].main,
          },
          '& .Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        // @ts-ignore
        variant: 'outlined',
        // @ts-ignore
        color: 'purple-2',
        sx: {
          '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
            transition: 'border-color 0.2s',
          },
          '& .MuiInputBase-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: COLORS['purple-2'].main,
            },
          '& .MuiInputBase-input': {
            color: COLORS['grey-8'].main,
          },
          '& .MuiInputBase-root': {
            borderRadius: '6px',
          },
          '& .MuiFormLabel-root:not(.Mui-focused)': {
            color: COLORS['grey-7'].main,
          },
          '& .Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
});
